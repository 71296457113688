import { TitleBar } from '../components/TitleBar';
import '../assets/Home.css';
import { UpcomingEvents } from '../components/UpcomingEvents';

const adultXVsEvents = [
    {
        date: 'Sept 30th/Oct 3rd',
        event: 'WELCOME WEEK - Sunnyvale, CA'
    },
    {
        date: 'Oct 1st',
        event: `XVs '24/'25 Season Starts`
    },
    {
        date: 'Oct 10th',
        event: 'SV Gear Swap'
    },
    {
        date: 'Oct 17th',
        event: '3rd Annual Combine'
    },
    {
        date: 'Nov 9th',
        event: 'Club Day - SV Black vs SV Red'
    },
    {
        date: 'Nov 16th',
        event: `Border 7's`
    },
    {
        date: 'Nov 23rd',
        event: 'XVs Pre-Season Match #1 vs. SMC'
    },
    {
        date: 'Jan 4th',
        event: `Women's 10s vs All Blues`
    },
    {
        date: 'Jan 25th',
        event: 'XVs Season Starts'
    },
    {
        date: 'April 26th',
        event: 'XVs Regionals'
    },
];

const hsEvents = [
    {
        date: 'March 8th',
        event: 'vs. Carmicheal'
    },
    {
        date: 'March 15th',
        event: '@Wolverines'
    },
    {
        date: 'March 22nd',
        event: 'Bye'
    },
    {
        date: 'March 29th',
        event: '@RazorHawks'
    },
    {
        date: 'April 5th',
        event: 'Bye'
    },
    {
        date: 'April 12th',
        event: '@Elsie Allen'
    },
    {
        date: 'April 19th',
        event: 'vs. Lamorinda'
    },
    {
        date: 'April 26th',
        event: 'vs. Barbarians'
    },
    {
        date: 'May 3rd',
        event: '@SacAIR'
    },
    {
        date: 'May 10th',
        event: 'Championships'
    },
];

const youthPreseasonEvents = [
    {
        date: 'October 21st',
        event: '1st Junior Youth Training (Washington Park)'
    },
    {
        date: 'October 24th',
        event: '2nd Junior Youth Training (Fair Oaks Park)'
    },
    {
        date: 'November 2nd',
        event: `NorCal development summit at Saint Mary's College`
    },
    {
        date: 'December 8th',
        event: 'Friendly @ SFGG + Cavs'
    },
    {
        date: 'December 14th',
        event: 'Friendly vs EPA Location TBD CANCELED'
    },
    {
        date: 'December 22nd',
        event: 'Friendly @ Marin'
    },
];

const youthSeasonEvents = [
    {
        date: 'January 5th',
        event: 'vs. SFGG + Danville'
    },
    {
        date: 'January 11th',
        event: 'vs. E Palo Alto RazorHawks'
    },
    {
        date: 'January 18th',
        event: 'Bay Area Tournament 1: @Pleasanton/@Danville'
    },
    {
        date: 'January 25th',
        event: 'vs. Marin'
    },
    {
        date: 'February 2nd',
        event: '@Lamo'
    },
    {
        date: 'February 8th',
        event: 'Cencal Challenge (U8s @SFGG)'
    },
    {
        date: 'February 15th',
        event: 'Age Grade Bye; MS on tour in England!'
    },
    {
        date: 'February 22nd',
        event: 'TBD'
    },
    {
        date: 'March 1st',
        event: 'TBD'
    },
    {
        date: 'March 8th',
        event: 'U8-U10-U12 EOY Tournament'
    },
    {
        date: 'March 14th',
        event: 'MS EOY Tournament'
    },
];


export const HomePage = () => {
    return (
        <div className='home-wrapper'>
            <TitleBar title='Home'/>
            <div className='title'>The Winter Rugby Union season is upon us! Matches start in January</div>
            <p>It's not too late to join us - bring three friends to training at Fair Oaks Park, and try out the game they play in heaven.</p>
            <p>Youth: 6:00 PM , Modays & Thursdays</p>
            <p>HS: 6:30 PM, Thursdays</p>
            <p>Adults: 7:00 PM, Mondays & Thursdays</p>
            <p>
                Fair Oaks Park<br></br>
                540 N Fair Oaks Ave<br></br>
                Sunnyvale, CA 94085
            </p>

            <div className='title'>Additional ways to be involved with SV</div>
            <p>Buy Silicon Valley Rugby logo gear at our <a href="https://www.worldrugbyshop.com/collections/silicon-valley-high-school-mens-rugby-club/">Pro Shop</a> by World Rugby Shop: All purchases at the shop rebate 10% of the list price to Silicon Valley Rugby. Support Silicon Valley in spirit and by raising funds.</p>
            <p>A note: Canterbury Clothing Company (CCC) garments tend to feature a modern, athletic fit. Some perceive that as running “small”. If you are considering an XL or larger size, consider sizing up.</p> 
            <p>A great way to be stay involved with SV Rugby is by becoming a donor or sponsor, SV is a 501(c)(3) nonprofit organization.</p>
            <p>If you have interest in donating or becoming a sponsor, please reach out.</p>
            <p>Thank you for all the support. GO SV!!</p>
            <div className='title'>Silicon Valley Rugby</div>
            <p>Silicon Valley RFC is a rugby club based in Silicon Valley, California, founded in 1998, as an Eagle Scout project creating our High School side. Our club offers junior youth, high school and adult rugby programs.</p> 
            <p>Our club mission, "To have a positive impact on peoples lives, through a thriving rugby club and community in Silicon Valley"</p>
            <p>Our club Core Values are:</p>
            <ul>
                <li>Community - Out of many, One</li>
                <li>Selflessness - we strive for the greater good of the club and community</li>
                <li>Focus - we are always moving forward</li>
                <li>Accountability - we adhere to the highest standard</li>
            </ul>
            <p>SVRFC is a non-profit Federal EIN: 27-0621542</p>
            <UpcomingEvents title={`'24 / '25 Adults XVs Schedule`} events={adultXVsEvents} />
            <UpcomingEvents title={`'24 / '25  XVs Season - High School`} events={hsEvents} />
            <p>To keep up with all communication with the high school team please download the <a href="https://www.sportsyou.com/">SportsYou App</a>.</p>
            <p>The team code is "PWF89BQR"</p>
            
            <h3>'24 / '25  XVs Season  - U8, U10, U12, U14 (Middle School)</h3>
            <UpcomingEvents title={`Pre-season:`} events={youthPreseasonEvents} />
            <UpcomingEvents title={`Match season:`} events={youthSeasonEvents} />
        </div>
    );
}